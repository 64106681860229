import cx from "classnames";
import React from "react";
import Icon, { IconKeys } from "assets/icons/Icon";
import styles from "./Alert.module.scss";

/**
 *
 * @returns An alert component, with various states including danger, success, warning and information.
 *
 * todo Add a reusable close button and functionality, so this can be hidden.
 * todo Add more options for width and sizing of this component
 */

interface AlertProps {
  centered?: boolean;
  className?: string;
  contentCentered?: boolean;
  description: string;
  fill?: "light" | "solid";
  fullWidth?: boolean;
  showIcon?: boolean;
  title?: string;
  textCentered?: boolean;
  variant?: "error" | "success" | "warning" | "info";
}

const Alert = ({
  centered,
  className,
  contentCentered,
  description,
  fill = "light",
  fullWidth,
  showIcon,
  title,
  textCentered,
  variant = "success",
}: AlertProps) => {
  const classNames = cx(
    styles.alert,
    {
      [styles.error]: variant === "error",
      [styles.success]: variant === "success",
      [styles.warning]: variant === "warning",
      [styles.info]: variant === "info",
      [styles.light]: fill === "light",
      [styles.solid]: fill === "solid",
      [styles.fullWidth]: fullWidth,
      [styles.contentCentered]: contentCentered,
      [styles.centered]: centered,
      [styles.textCentered]: textCentered,
    },
    className
  );

  const defaultIcons: { [key: string]: IconKeys } = {
    error: "GtDanger",
    info: "GtInfo",
    success: "GtCheckmark",
    warning: "GtWarning",
  };
  return (
    <div className={classNames}>
      {showIcon ? (
        <div className={styles.icon}>
          <Icon icon={defaultIcons[variant]} />
        </div>
      ) : null}
      <div className={styles.textContent}>
        {title && <h5 className={styles.title}>{title}</h5>}
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default Alert;
