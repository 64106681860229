import cx from "classnames";
import React from "react";
import { MdClear, MdSearch } from "react-icons/md";
import Button from "../../generic/Button/Button";
import IconButton from "../../generic/IconButton/IconButton";
import Input from "../../generic/Input/Input";
import Text from "../../generic/Text/Text";
import styles from "./ClientsHeader.module.scss";

interface ClientsHeaderProps {
  clientCount: number;
  isLoading: boolean;
  searchActive: boolean;
  handleClearFilter: () => void;
  handleSearchToggle: () => void;
  handleToggleForm: () => void;
  setFilter: (value: string) => void;
  filter: string;
}

type ClientsHeaderRef = HTMLInputElement;

const ClientsHeader = React.forwardRef<ClientsHeaderRef, ClientsHeaderProps>(
  (
    {
      clientCount,
      filter,
      isLoading,
      searchActive,
      handleClearFilter,
      handleSearchToggle,
      handleToggleForm,
      setFilter,
    },
    ref
  ) => (
    <div className={styles.header}>
      <Text
        className={styles.header_title}
        variant="subheading"
        weight="regular"
      >
        All clients
      </Text>
      <div className={styles.header_group}>
        <Button
          className={styles.header_add_client}
          size="sm"
          isDisabled={isLoading}
          onClick={() => handleToggleForm()}
        >
          Add client
        </Button>
        {((clientCount > 0 && filter === "") || filter !== "") && (
          <div className={styles.header_search}>
            <IconButton
              className={styles.header_search_toggle}
              variant="ghost"
              isDisabled={isLoading}
              size="md"
              label="Search for a client"
              onClick={
                filter === ""
                  ? () => handleSearchToggle()
                  : () => handleClearFilter()
              }
            >
              {filter !== "" ? <MdClear /> : <MdSearch />}
            </IconButton>
            <div
              className={cx(styles.header_input_container, {
                [styles.active]: searchActive,
              })}
            >
              <Input
                ref={ref}
                size="sm"
                value={filter}
                isDisabled={isLoading}
                className={styles.header_input}
                placeholder="Search by email"
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
);

export default ClientsHeader;
