import { Client } from "components/AdminDashboard/useAdminDashboard";
import ClientForm from "components/Form/ClientForm";
import React, { useState } from "react";
import ClientPagination from "./ClientPagination";
import styles from "./Clients.module.scss";
import ClientsEmptyState from "./ClientsEmptyState";
import ClientsGrid from "./ClientsGrid";
import ClientCard from "./ClientsGrid/ClientCard";
import ClientsHeader from "./ClientsHeader";
import useClientDisplay, { PAGE_SIZE } from "./useClientDisplay";

interface ClientsProps {
  accessToken: string;
  isLoading: boolean;
  clients: Client[];
}

/**
 * Displays clients for /admin/clients route.
 */
function Clients({ accessToken, isLoading = true, clients }: ClientsProps) {
  const [formActive, setFormActive] = useState(false);
  const {
    display: {
      activeClients,
      currentClients,
      currentPage,
      filter,
      pagination: { startingCount, endingCount },
      searchActive,
      inputRef,
    },
    handlers: {
      setCurrentPage,
      handleFilterSearch,
      handleSearchToggle,
      handleClearFilter,
    },
  } = useClientDisplay(clients);

  const handleToggleForm = () => {
    setFormActive((prev) => !prev);
  };

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <ClientsHeader
          clientCount={activeClients?.length ?? 0}
          searchActive={searchActive}
          handleSearchToggle={handleSearchToggle}
          handleClearFilter={handleClearFilter}
          handleToggleForm={handleToggleForm}
          filter={filter}
          isLoading={isLoading}
          setFilter={handleFilterSearch}
          ref={inputRef}
        />
        {!isLoading && currentClients?.length === 0 ? (
          <ClientsEmptyState filterApplied={!!filter} />
        ) : (
          <ClientsGrid>
            {currentClients?.map((c) => (
              <ClientCard key={c.email} {...c} />
            ))}
          </ClientsGrid>
        )}
        <ClientPagination
          clientCount={activeClients.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          startingCount={startingCount}
          endingCount={endingCount}
          pageSize={PAGE_SIZE}
        />
        {formActive && (
          <ClientForm
            accessToken={accessToken}
            handleToggleForm={handleToggleForm}
          />
        )}
      </div>
    </section>
  );
}

export default Clients;
