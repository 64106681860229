import {
  SavedState,
  SaveReportState,
} from "components/AdminDashboard/useAdminDashboard";
import { State } from "hooks/useReport";
import { models } from "powerbi-client";
import React from "react";
import { useParams } from "react-router-dom";
import ResultsDashboard from "./ResultsDashboard";

interface AdminResultsProps {
  accessToken: string;
  saveReportState: SaveReportState;
  savedReportStates?: SavedState[];
}

export type ClientParams = {
  clientEmail: string;
};

function AdminResults({
  accessToken,
  saveReportState,
  savedReportStates,
}: AdminResultsProps) {
  const { clientEmail } = useParams<ClientParams>();

  const handleSaveReportState = (reportState: State) => {
    saveReportState(reportState, clientEmail);
  };

  const basicFilter: models.IBasicFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "JP_DMA_submissions",
      column: "email",
    },
    operator: "In",
    values: [clientEmail],
    filterType: models.FilterType.Basic,
    requireSingleSelection: true,
  };
  return (
    <ResultsDashboard
      passedInitialState={
        savedReportStates?.find((state) => state.email === clientEmail)?.state
      }
      saveReportState={handleSaveReportState}
      accessToken={accessToken}
      initialFilter={basicFilter}
    />
  );
}

export default AdminResults;
