import React from "react";
import PropTypes from "prop-types";
import Text from "../../generic/Text/Text";
import styles from "./ClientsEmptyState.module.scss";

interface ClientsEmptyStateProps {
  filterApplied: boolean;
}

const ClientsEmptyState = ({ filterApplied }: ClientsEmptyStateProps) => {
  const message = filterApplied
    ? "No results for your search. Please try again."
    : "You have no clients. Begin adding clients now.";

  return <Text className={styles.empty}>{message}</Text>;
};

export default ClientsEmptyState;

ClientsEmptyState.propTypes = {
  filterApplied: PropTypes.bool,
};
