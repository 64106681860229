import React, { useRef } from "react";
import FocusTrap from "focus-trap-react";
import useOnClickOutside from "components/generic/hooks/useOnClickOutside";
import Form from "..";
import Alert from "components/generic/Alert";
import Modal from "components/generic/Modal/Modal";
import styles from "./ClientForm.module.scss";
import useClientForm from "./useClientForm";
import FormControl from "components/generic/FormControl/FormControl";
import FormLabel from "components/generic/FormControl/FormLabel/FormLabel";
import Input from "components/generic/Input/Input";
import FormErrorCaption from "components/generic/FormControl/FormErrorCaption/FormErrorCaption";
import { Fields } from "./useClientForm";
import Text from "components/generic/Text/Text";
import Button from "components/generic/Button/Button";

const trimValues = (object: Record<string, string | number>): Fields =>
  Object.keys(object).reduce(
    (returnedObject: Fields, currentKey) => ({
      ...returnedObject,
      [currentKey]:
        typeof object[currentKey] === "string"
          ? (object[currentKey] as string).trim()
          : object[currentKey],
    }),
    {
      email: "",
      contactName: "",
      company: "",
    }
  );

interface ClientFormProps {
  accessToken: string;
  handleToggleForm: () => void;
}

const ClientForm = ({ accessToken, handleToggleForm }: ClientFormProps) => {
  const wrapperRef = useRef(null);

  const { store, handlers } = useClientForm({ accessToken });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedFields = trimValues(store.formFields);
    handlers.handleSubmit(trimmedFields);
  };

  const handleOnCancel = () => {
    handleToggleForm();
    handlers.resetForm();
  };

  useOnClickOutside(wrapperRef, handleToggleForm);

  return (
    <Modal>
      <FocusTrap>
        <div ref={wrapperRef} className={styles.wrapper}>
          {store.formState === "isSubmitted" ? (
            <FormSubmittedDialog onResetForm={handlers.resetForm} />
          ) : (
            <Form
              icon={"GtTeam"}
              title="Submit a request to add a new client"
              onSubmit={onSubmit}
              isLoading={store.formState === "isLoading"}
              cancelMessage="Cancel"
              submitMessage="Submit"
              loadingText="Submitting..."
              onCancel={handleOnCancel}
            >
              <fieldset className={styles.form_fields}>
                <FormControl
                  id="contactName"
                  name="contactName"
                  isInvalid={store.formErrors.contactName !== ""}
                  isRequired
                >
                  <FormLabel>Contact name</FormLabel>
                  <Input
                    value={store.formFields.contactName}
                    onChange={(e) =>
                      handlers.handleField("contactName", e.target.value)
                    }
                  />
                  <FormErrorCaption>
                    {store.formErrors.contactName}
                  </FormErrorCaption>
                </FormControl>
                <FormControl
                  id="company"
                  name="company"
                  isInvalid={store.formErrors.company !== ""}
                  isRequired
                >
                  <FormLabel>Company name</FormLabel>
                  <Input
                    value={store.formFields.company}
                    onChange={(e) =>
                      handlers.handleField("company", e.target.value)
                    }
                  />
                  <FormErrorCaption>
                    {store.formErrors.company}
                  </FormErrorCaption>
                </FormControl>
              </fieldset>
              <fieldset className={styles.form_fields}>
                <FormControl
                  id="email"
                  name="email"
                  isInvalid={store.formErrors.email !== ""}
                  isRequired
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    value={store.formFields.email}
                    onChange={(e) =>
                      handlers.handleField("email", e.target.value)
                    }
                  />
                  <FormErrorCaption>{store.formErrors.email}</FormErrorCaption>
                </FormControl>
              </fieldset>
              <Alert
                className={styles.form_alert}
                title="Please note"
                variant="warning"
                description="The email address that is entered must be a Microsoft account that has an active Power BI Pro license."
                fullWidth
              />
              {store.formErrors.form && (
                <Text className={styles.form_error}>
                  {store.formErrors.form}
                </Text>
              )}
            </Form>
          )}
        </div>
      </FocusTrap>
    </Modal>
  );
};

export default ClientForm;

interface FormSubmittedDialogProps {
  onResetForm: () => void;
}

const FormSubmittedDialog = ({ onResetForm }: FormSubmittedDialogProps) => {
  return (
    <div className={styles.form_submitted}>
      <Text className={styles.form_submitted_title}>
        Your request has been submitted. An email will also be sent to you for
        your records.
      </Text>
      <Button size="sm" onClick={onResetForm}>
        Add another client
      </Button>
    </div>
  );
};
