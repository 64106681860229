import {
  AdminDashboardProps,
  Client,
  GetAvailableClients,
} from "components/AdminDashboard/useAdminDashboard";
import Clients from "components/Clients";
import Loader from "components/generic/Loader/Loader";
import PowerBI from "components/PowerBI";

interface AdminLaunchpadProps extends Pick<AdminDashboardProps, "accessToken"> {
  clients: Client[];
  isLoading: boolean;
  getAvailableClients: GetAvailableClients;
}

/**
 * Houses the launchpad for admins to initiate adding clients
 */

function AdminLaunchpad({
  accessToken,
  clients,
  isLoading,
  getAvailableClients,
}: AdminLaunchpadProps) {
  return (
    <Loader
      disableTransition={!isLoading}
      isLoading={isLoading}
      loadingText="Fetching your clients..."
    >
      <PowerBI
        accessToken={accessToken}
        initializeReportCallback={getAvailableClients}
        isLoaded={!isLoading}
      />
      <Clients
        accessToken={accessToken}
        clients={clients}
        isLoading={isLoading}
      />
    </Loader>
  );
}

export default AdminLaunchpad;
