import React from "react";
import cx from "classnames";
import { node, oneOf, bool, string, arrayOf, oneOfType } from "prop-types";
import styles from "./ButtonGroup.module.scss";
import { ButtonVariants, ButtonSizes } from "../Button/Button";

/**
 *
 * @returns A component that wraps button components, and allows you to pass down styling props such as variant and sizes to each individual button. Only 2 buttons can be passed to this component.
 *
 */

interface ButtonGroupProps {
  children: React.ReactNode;
  /** For CSS customisation */
  className?: string;
  /** If true, the buttons will have no spacing */
  isAttached?: boolean;
  /** How the content is positioned within the group */
  justify?: "left" | "start" | "center" | "right" | "end" | "space-between";
  /** Used to flip order of buttons in the group */
  orderFlipped?: boolean;
  /** Passes size to each individual button */
  size?: ButtonSizes;
  /** Spacing between the buttons */
  spacing?: "xl" | "lg" | "md" | "sm";
  /** Passes variant to each individual button */
  variant?: ButtonVariants[] | ButtonVariants;
}

const ButtonGroup = ({
  children,
  className,
  isAttached,
  justify,
  orderFlipped,
  size = "md",
  spacing = "lg",
  variant,
}: ButtonGroupProps) => {
  const classNames = cx(
    styles.group,
    {
      [styles.flipped]: orderFlipped === true,
      [styles.spacingXl]: spacing === "xl",
      [styles.spacingLg]: spacing === "lg",
      [styles.spacingMd]: spacing === "md",
      [styles.spacingSm]: spacing === "sm",
      [styles.justifyStart]: justify === "left" || justify === "start",
      [styles.justifyCenter]: justify === "center",
      [styles.justifyEnd]: justify === "right" || justify === "end",
      [styles.justifyBetween]: justify === "space-between",
      [styles.attached]: isAttached,
    },
    className
  );
  const childrenWithProps = React.Children.map(children, (child, index) => {
    // Only 2 buttons allowed to be returned
    if (index > 1) return null;
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        size,
        variant: Array.isArray(variant) ? variant[index] : variant,
      });
    }
    return child;
  });
  return (
    <div className={classNames}>
      {orderFlipped ? childrenWithProps!.reverse() : childrenWithProps}
    </div>
  );
};

export default ButtonGroup;
