import { useState, useRef, useMemo, useEffect } from "react";
import { Client } from "components/AdminDashboard/useAdminDashboard";

export const PAGE_SIZE = 6;

/**
 * Manages the display of clients, covering filtering and pagination
 */
export default function useClientDisplay(clients: Client[]) {
  const [filter, setFilter] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const inputRef = useRef<HTMLInputElement | null>(null);

  /** Reset pagination page back to 1 on filter to avoid any bugs
   */
  const handleFilterSearch = (value: string) => {
    setFilter(value);
    setCurrentPage(1);
  };

  const handleSetSearchActive = (state: boolean) => {
    setSearchActive(state);
  };

  const handleSearchToggle = () => {
    if (!searchActive) {
      handleSetSearchActive(true);
      inputRef.current!.focus();
    } else {
      handleSetSearchActive(false);
      inputRef.current!.blur();
    }
  };

  const handleClearFilter = () => {
    setFilter("");
    setCurrentPage(1);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  /** The active clients, if they are being filtered by search */
  const activeClients = useMemo(
    () =>
      clients.filter((client) => {
        if (filter === "") return client;
        return client.email.toLowerCase().includes(filter.toLowerCase());
      }),
    [clients, filter]
  );

  /** The current clients being displayed */
  const { startingCount, endingCount, currentClients } = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;

    const slicedClients = activeClients.slice(firstPageIndex, lastPageIndex);

    return {
      currentClients: slicedClients,
      endingCount:
        slicedClients.length === PAGE_SIZE
          ? lastPageIndex
          : activeClients.length,
      startingCount: firstPageIndex + 1,
    };
  }, [currentPage, activeClients]);

  /**
   * When there is 1 result left on the paginated page and it is removed,
   * go back a page.
   */
  useEffect(() => {
    if (clients.length > 0 && currentClients.length === 0) {
      setCurrentPage((prev) => {
        if (prev === 0) return 0;

        return prev - 1;
      });
    }
  }, [clients.length, currentClients.length]);

  return {
    display: {
      activeClients,
      currentClients,
      currentPage,
      filter,
      inputRef,
      pagination: {
        endingCount,
        startingCount,
      },
      searchActive,
    },
    handlers: {
      handleClearFilter,
      handleFilterSearch,
      handleSearchToggle,
      handleSetSearchActive,
      setCurrentPage,
    },
  };
}
