import { AuthenticationResult, InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import ErrorBoundary from "components/ErrorBoundary";
import LanguageSelector from "components/LanguageSelector/LanguageSelector";
import ForbiddenPage from "pages/ForbiddenPage";
import { useEffect, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import "./i18n/config";

import Dashboard from "components/Dashboard";
import AdminResults from "pages/AdminResults";
import AdminLaunchpad from "pages/AdminLaunchpad";
import useAdminDashboard from "./components/AdminDashboard/useAdminDashboard";

type IdTokenClaims = {
  roles: string[];
};

export type UserDetails = {
  accessToken: string;
  name: string;
};

export type UserConfig = {
  accessToken?: string;
  roles?: string[];
};

function App() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const history = useHistory();
  const [response, setResponse] = useState<AuthenticationResult>();
  const { store, handlers } = useAdminDashboard();

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          account,
          scopes: ["https://analysis.windows.net/powerbi/api/.default"],
        })
        .then((response) => {
          if (response) {
            setResponse(response);
            if (response.account) {
              const idClaims = response.account.idTokenClaims as IdTokenClaims;
              const { roles } = idClaims;
              
              // If the user does not have a client role assigned.
              if (!roles?.find((role) => role === "admin")) {
                history.replace("/forbidden");
                return;
              }
              return;
            }
          }
        });
    }
  }, [account, instance]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <SkeletonTheme baseColor="#f1f1f4" highlightColor="#f9f9fb">
        <ErrorBoundary>
          <LanguageSelector />
          <Switch>
            {/* <Route path="/" exact>
              {console.log("see me ", rolesArray)}
              <AdminRoutes accessToken={response?.accessToken} roles={rolesArray} />
            </Route> */}
            <Route exact path="/forbidden">
              <ForbiddenPage />
            </Route>
            <Route path="/" exact>
              <Dashboard isAdminView>
                <AdminLaunchpad
                  clients={store.clients}
                  getAvailableClients={handlers.getAvailableClients}
                  accessToken={response?.accessToken || ""}
                  isLoading={store.isLoading}
                />
              </Dashboard>
            </Route>
            <Route path={`/results/:clientEmail`} exact>
              <Dashboard isAdminView>
                <AdminResults
                  saveReportState={handlers.saveReportState}
                  savedReportStates={store.savedStates}
                  accessToken={response?.accessToken || ""}
                />
              </Dashboard>
            </Route>
          </Switch>
        </ErrorBoundary>
      </SkeletonTheme>
    </MsalAuthenticationTemplate>
  );
}

export default withRouter(App);
