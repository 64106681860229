import React from "react";
import cx from "classnames";
import { string, node } from "prop-types";
import Icon, { IconKeys } from "assets/icons/Icon";
import styles from "./FormErrorCaption.module.scss";
import { useFormControlContext } from "../FormControl";

/**
 * Returns a helper caption for an input, to help the user
 * when entering values in an input. Will not render anything
 * if the parent `FormControl` is valid.
 */

interface FormErrorCaptionProps {
  className?: string;
  children: React.ReactNode;
  icon?: IconKeys;
}

function FormErrorCaption({
  className,
  children,
  icon,
  ...rest
}: FormErrorCaptionProps) {
  const classNames = cx(styles.error, className);
  const groupValues = useFormControlContext();

  // Do not render error caption if form is valid.
  if (!groupValues?.isInvalid) {
    return null;
  }

  const errorProps = groupValues?.getErrorCaptionProps(rest) ?? { ...rest };
  return (
    <span className={classNames} {...errorProps}>
      {icon && <Icon icon={icon} className={styles.icon} />}
      {children}
    </span>
  );
}

export default FormErrorCaption;

FormErrorCaption.propTypes = {
  /** The content of the caption */
  children: node.isRequired,
  /** For CSS customisation */
  className: string,
  /** An optional icon to be displayed */
  icon: string,
};
