import React from "react";
import Pagination from "../../Pagination/index";
import Text from "../../generic/Text/Text";
import styles from "./ClientPagination.module.scss";

interface ClientPaginationProps {
  clientCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  startingCount: number;
  endingCount: number;
  pageSize: number;
}

const ClientPagination = ({
  clientCount,
  currentPage,
  setCurrentPage,
  startingCount,
  endingCount,
  pageSize,
}: ClientPaginationProps) => {
  const paginationText =
    clientCount === 0
      ? `No results`
      : `Showing ${startingCount} - ${endingCount} of ${clientCount} results`;
  return (
    <div className={styles.pagination}>
      <Text className={styles.pagination_text}>{paginationText}</Text>
      <Pagination
        className={styles.pagination_element}
        currentPage={currentPage}
        totalCount={clientCount}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ClientPagination;
