import { useReducer } from "react";
import { Report } from "powerbi-client";
import { parseClientsSubmissionsData } from "utils/function";
import { models } from "powerbi-client";
import { State as ReportState } from "hooks/useReport";

export type Client = {
  email: string;
  score: number;
};

export type GetAvailableClients = (report: Report) => Promise<void>;
export type SaveReportState = (reportState: ReportState, email: string) => void;

export interface AdminDashboardProps {
  accessToken: string;
}

export type SavedState = {
  email: string;
  state: ReportState;
};

interface State {
  clients: Client[];
  hasFetched: boolean;
  isLoading: boolean;
  savedStates: SavedState[];
}

type ACTIONS =
  | { type: "set-loading"; payload: { state: boolean } }
  | { type: "set-clients"; payload: { clients: Client[] } }
  | { type: "set-fetched"; payload: { state: boolean } }
  | { type: "set-saved-state"; payload: { state: ReportState; email: string } };

function reducer(state: State, action: ACTIONS): State {
  if (action.type === "set-loading") {
    return {
      ...state,
      isLoading: action.payload.state,
    };
  }

  if (action.type === "set-clients") {
    return {
      ...state,
      clients: [...action.payload.clients],
    };
  }

  if (action.type === "set-fetched") {
    return {
      ...state,
      hasFetched: action.payload.state,
    };
  }

  if (action.type === "set-saved-state") {
    if (
      state.savedStates.find((client) => client.email === action.payload.email)
    ) {
      return state;
    }
    return {
      ...state,
      savedStates: [
        ...state.savedStates,
        {
          email: action.payload.email,
          state: action.payload.state,
        },
      ],
    };
  }
  return state;
}

const initialState = {
  clients: [],
  hasFetched: false,
  isLoading: true,
  savedStates: [],
};

/**
 * Hook for managing available clients on the admin dashboard. As well, whenever a report
 * is viewed it saves the state so the admin can view the report again without re-loading
 * the Power BI report.
 */

export default function useAdminDashboard() {
  const [store, dispatch] = useReducer(reducer, initialState);

  const getAvailableClients = async (report: Report) => {
    if (store.hasFetched) return;
    try {
      const pages = await report.getPages();
      const page = pages[0];

      const clientsVisual = await page.getVisualByName("6b998559b939256dc841");

      const clientsVisualData = await clientsVisual.exportData(
        models.ExportDataType.Summarized
      );

      const parsedClientsVisualData = parseClientsSubmissionsData(
        clientsVisualData.data
      );

      dispatch({
        type: "set-clients",
        payload: {
          clients: parsedClientsVisualData,
        },
      });
      dispatch({
        type: "set-loading",
        payload: {
          state: false,
        },
      });
      dispatch({
        type: "set-fetched",
        payload: {
          state: true,
        },
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  // Save the report states, so they don't need to be reloaded in the same session
  const saveReportState = (reportState: ReportState, email: string) => {
    dispatch({
      type: "set-saved-state",
      payload: {
        state: reportState,
        email,
      },
    });
  };

  return {
    store,
    handlers: {
      getAvailableClients,
      saveReportState,
    },
  };
}
