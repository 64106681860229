import Icon, { IconKeys } from "assets/icons/Icon";
import cx from "classnames";
import Button from "components/generic/Button/Button";
import ButtonGroup from "components/generic/ButtonGroup/ButtonGroup";
import Text from "components/generic/Text/Text";
import React from "react";
import styles from "./Form.module.scss";

interface FormProps {
  title: string;
  icon: IconKeys;
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
  children: React.ReactNode;
  className?: string;
  loadingText?: string;
  submitMessage?: string;
  cancelMessage?: string;
  isLoading?: boolean;
}

type FormRef = HTMLFormElement;

const Form = React.forwardRef<FormRef, FormProps>((props, ref) => {
  const {
    title,
    icon,
    onSubmit,
    onCancel,
    children,
    className,
    loadingText = "Loading...",
    submitMessage = "Confirm",
    cancelMessage = "Cancel",
    isLoading,
  } = props;
  return (
    <form ref={ref} className={cx(styles.form, className)} onSubmit={onSubmit}>
      <div className={styles.form_body}>
        <header className={styles.form_header}>
          <div className={styles.form_icon}>
            <Icon icon={icon} />
          </div>
          <Text className={styles.form_title} variant="bodyLarge">
            {title}
          </Text>
        </header>
        <div className={styles.form_fields}>{children}</div>
      </div>
      <div className={styles.form_footer}>
        <ButtonGroup
          className={styles.form_footer_group}
          justify="space-between"
          size="md"
          variant={["filled", "ghost"]}
        >
          <Button isLoading={isLoading} loadingText={loadingText} type="submit">
            {submitMessage}
          </Button>
          <Button onClick={onCancel}>{cancelMessage}</Button>
        </ButtonGroup>
      </div>
    </form>
  );
});

export default Form;
