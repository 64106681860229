import { useFormControlContext } from "./FormControl";
import { ariaAttributes } from "../../../utils/dom";

/**
 * A react hook for controlling the props that shoudl be
 * spread onto input fields in a `FormControl` component
 */

export function useFormControlProps(props) {
  // Get the values within the context of the form group
  const fieldValues = useFormControlContext();

  const {
    id,
    disabled,
    readOnly,
    required,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    name,
    size,
    ...rest
  } = props;

  return {
    ...rest,
    id: id ?? fieldValues?.id,
    isDisabled: disabled ?? isDisabled ?? fieldValues?.isDisabled,
    isInvalid: isInvalid ?? fieldValues?.isInvalid,
    isReadOnly: readOnly ?? isReadOnly ?? fieldValues?.isReadOnly,
    isRequired: required ?? isRequired ?? fieldValues?.isRequired,
    name: fieldValues?.name ?? name,
    size: fieldValues?.size ?? size ?? "md",
  };
}

export function useFormControl(props) {
  const { isDisabled, isInvalid, isReadOnly, isRequired, isValid, ...rest } =
    useFormControlProps(props);

  return {
    ...rest,
    "aria-disabled": ariaAttributes(isDisabled),
    "aria-invalid": ariaAttributes(isInvalid),
    "aria-readonly": ariaAttributes(isReadOnly),
    "aria-required": ariaAttributes(isRequired),
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
  };
}
