import { Client } from "components/AdminDashboard/useAdminDashboard";
import Link from "components/generic/Link";
import styles from "./ClientCard.module.scss";
import { Link as RouterLink } from "react-router-dom";
import Text from "components/generic/Text/Text";
import { getRankLabelByScore } from "utils/function";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ClientCardProps extends Client {}

function ClientCard({ email, score }: ClientCardProps) {
  
  return (
    <div className={styles.card}>
      <div className={styles.card_body}>
        <div className={styles.card_header}>
          <div className={styles.card_score}>{score ?? "n/a"}</div>
          <div className={styles.card_client}>
            <Text variant="overline" className={styles.card_overline}>
              Client email
            </Text>
            <Text variant="bodyLarge" className={styles.card_name}>
              {email}
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.card_footer}>
        <Text className={styles.card_footer_rank}>
          {getRankLabelByScore(score)}
        </Text>
        <Link component={RouterLink} to={`/results/${email}`}>
          View report
        </Link>
      </div>
    </div>
  );
}

export default ClientCard;
