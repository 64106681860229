import 'dotenv/config';
import { useReducer } from "react";
import isEmail from "validator/es/lib/isEmail";
import axios from "axios";
import jwt_decode from "jwt-decode";


export interface Fields {
  email: string;
  contactName: string;
  company: string;
}

interface LogicAppBody extends Fields {
  org: string;
  requesterEmail: string;
}

type AccessToken = {
  unique_name: string;
};

export type FieldsKeys = keyof Fields;

type InputFields = Omit<Fields, "org" | "requesterEmail">;
type InputFieldKeys = keyof InputFields;

export type InputErrors =
  | "This field is required"
  | "Not a valid email"
  | "There was a problem submitting the form. Please try again.";

type FormState = "idle" | "isLoading" | "isSubmitted";

type ACTIONS =
  | { type: "handle-field"; payload: { field: InputFieldKeys; value: string } }
  | {
      type: "handle-error";
      payload: { field: InputFieldKeys | "form"; value?: InputErrors };
    }
  | { type: "set-form-state"; payload: { state: FormState } }
  | { type: "reset-form" }
  | { type: "set-requester-info"; payload: { email: string; org: string } };

interface State {
  formFields: {
    [key in keyof Fields]: string;
  };
  formErrors: {
    [key in InputFieldKeys | "form"]: string;
  };
  formState: FormState;
}

function reducer(state: State, action: ACTIONS): State {
  if (action.type === "handle-field") {
    return {
      ...state,
      formFields: {
        ...state.formFields,
        [action.payload.field]: action.payload.value,
      },
      formErrors: {
        ...state.formErrors,
        [action.payload.field]: "",
      },
    };
  }

  if (action.type === "handle-error") {
    return {
      ...state,
      formErrors: {
        ...state.formErrors,
        [action.payload.field]: action.payload.value ?? "",
      },
    };
  }

  if (action.type === "set-form-state") {
    return {
      ...state,
      formState: action.payload.state,
    };
  }

  if (action.type === "reset-form") {
    return initialState;
  }

  return state;
}

const initialState: State = {
  formFields: {
    email: "",
    contactName: "",
    company: "",
  },
  formErrors: {
    email: "",
    contactName: "",
    company: "",
    form: "",
  },
  formState: "idle",
};

export default function useClientForm({
  accessToken,
}: {
  accessToken: string;
}) {
  const [store, dispatch] = useReducer(reducer, initialState);

  const handleField = (field: InputFieldKeys, value: string) => {
    dispatch({
      type: "handle-field",
      payload: {
        field,
        value,
      },
    });
  };

  const handleError = (field: InputFieldKeys, value: InputErrors) => {
    dispatch({
      type: "handle-error",
      payload: {
        field,
        value,
      },
    });
  };

  const resetForm = () => {
    dispatch({
      type: "reset-form",
    });
  };

  const handleSubmit = async (fields: Fields) => {
    dispatch({
      type: "handle-error",
      payload: {
        field: "form",
      },
    });
    let formValid = true;

    if (fields.contactName === "") {
      dispatch({
        type: "handle-error",
        payload: {
          field: "contactName",
          value: "This field is required",
        },
      });
      formValid = false;
    }

    if (fields.company === "") {
      dispatch({
        type: "handle-error",
        payload: {
          field: "company",
          value: "This field is required",
        },
      });
      formValid = false;
    }

    if (fields.email === "") {
      dispatch({
        type: "handle-error",
        payload: {
          field: "email",
          value: "This field is required",
        },
      });
      formValid = false;
    } else if (!isEmail(fields.email)) {
      dispatch({
        type: "handle-error",
        payload: {
          field: "email",
          value: "Not a valid email",
        },
      });
      formValid = false;
    }

    /**
     * Move the user to the next step, confirming the details.
     */
    if (formValid) {
      dispatch({
        type: "set-form-state",
        payload: {
          state: "isLoading",
        },
      });

      try {
        const decodedAccessToken = jwt_decode(accessToken) as AccessToken;
        const add_client_api_URL = process.env.ADD_CLIENT_LOGICAPP_API || "https://prod-25.japaneast.logic.azure.com:443/workflows/dbe1e2ab0d154e3895220a24efc44760/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=15GjVLiJNo7J1FsKNQpgPSaRVF3UyPpLw3nKDERR-x0";

        // Taken from logic app schema
        const postBody: LogicAppBody = {
          ...fields,
          org: "GT Ireland",
          requesterEmail: decodedAccessToken.unique_name,
        };

        const response = await axios.post<LogicAppBody>(
          add_client_api_URL,
          postBody
        );

        if (response.status === 200) {
          dispatch({
            type: "set-form-state",
            payload: {
              state: "isSubmitted",
            },
          });
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: "set-form-state",
          payload: {
            state: "idle",
          },
        });
        dispatch({
          type: "handle-error",
          payload: {
            field: "form",
            value: "There was a problem submitting the form. Please try again.",
          },
        });
        dispatch({
          type: "set-form-state",
          payload: {
            state: "idle",
          },
        });
      }
    }
  };

  return {
    store,
    handlers: {
      handleField,
      handleError,
      handleSubmit,
      resetForm,
    },
  };
}
