import React from "react";
import cx from "classnames";
import { useFormControl } from "../FormControl/useFormControl";
import { useInputGroupContext } from "./InputGroup/InputGroup";
import styles from "./Input.module.scss";

/**
 * Returns a simple input component, which should be used within a
 * `FormControl` and with a `FormLabel` for accessibility.
 */

type InputRef = HTMLInputElement;

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  /** For CSS customisation */
  className?: string;
  /** If true, the input will be disabled */
  isDisabled?: boolean;
  /** If true, the input will be marked as invalid */
  isInvalid?: boolean;
  /** If true, the input will be marked as readonly */
  isReadOnly?: boolean;
  /** If true, the input will be required */
  isRequired?: boolean;
  /** The name of the input, which will be submitted with the form */
  name?: string;
  /** The function to run on input */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** The string shown when the input is untouched. Should not be used
   * in expense of a form label.
   */
  placeholder?: string;
  /** Options to change the size of the input */
  size?: "md" | "sm" | "xs";
  /** The value of the input */
  value: string;
}

const Input = React.forwardRef<InputRef, InputProps>(
  ({ className, size = "md", ...rest }, ref) => {
    // This returns props from the `FormControl` context, which controls the input.
    const inputProps = useFormControl(rest);
    // This returns props from the `InputGroup` context, which lets the input know if there is elements beside it such as `InputLeftElement` or `InputRightElement`.
    const groupProps = useInputGroupContext();
    const classNames = cx(
      styles.input,
      {
        [styles.md]: inputProps.size === "md" || size === "md",
        [styles.sm]: inputProps.size === "sm" || size === "sm",
        [styles.xs]: inputProps.size === "xs" || size === "xs",
        // Determine the padding of the input based on whether an absoloute positioned `InputLeftElement` or `InputRightElement` is present.
        [styles.leftElement_xl]: groupProps?.leftElement.size === "xl",
        [styles.leftElement_lg]: groupProps?.leftElement.size === "lg",
        [styles.leftElement_md]: groupProps?.leftElement.size === "md",
        [styles.leftElement_sm]: groupProps?.leftElement.size === "sm",
        [styles.leftElement_xs]: groupProps?.leftElement.size === "xs",
        [styles.rightElement_xl]: groupProps?.rightElement.size === "xl",
        [styles.rightElement_lg]: groupProps?.rightElement.size === "lg",
        [styles.rightElement_md]: groupProps?.rightElement.size === "md",
        [styles.rightElement_sm]: groupProps?.rightElement.size === "sm",
        [styles.rightElement_xs]: groupProps?.rightElement.size === "xs",
      },
      className
    );

    return <input ref={ref} {...inputProps} className={classNames} />;
  }
);

export default Input;
