import React from "react";
import cx from "classnames";
import Icon from "../../assets/icons/Icon";
import Text from "../generic/Text/Text";
import styles from "./Pagination.module.scss";
import usePagination, { DOTS } from "./usePagination";

interface PaginationProps {
  onPageChange: React.Dispatch<React.SetStateAction<number>>;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
}

function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: PaginationProps) {
  const paginationRange = usePagination({
    currentPage,
    pageSize,
    siblingCount,
    totalCount,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  console.log(currentPage);

  const lastPage =
    paginationRange && paginationRange[paginationRange.length - 1];

  return (
    <div className={cx(styles.pagination, className)}>
      <PaginationPrevious isDisabled={currentPage === 1} onClick={onPrevious} />
      {paginationRange?.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <PaginationDots />;
        }

        return (
          <PaginationNumber
            key={pageNumber}
            onClick={() => onPageChange(pageNumber as number)}
            isActive={currentPage === (pageNumber as number)}
          >
            {pageNumber}
          </PaginationNumber>
        );
      })}
      <PaginationNext isDisabled={currentPage === lastPage} onClick={onNext} />
    </div>
  );
}

export default Pagination;

interface PaginationButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "type"> {
  children: React.ReactNode;
  isDirectionButton?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
}

function PaginationButton({
  children,
  isDirectionButton = false,
  isDisabled,
  isActive,
  ...rest
}: PaginationButtonProps) {
  return (
    <button
      type="button"
      {...rest}
      disabled={isDisabled}
      className={cx(styles.pagination_button, {
        [styles.direction]: isDirectionButton,
        [styles.disabled]: isDisabled,
        [styles.active]: isActive,
      })}
    >
      {children}
    </button>
  );
}

interface PaginationNumberProps {
  children: React.ReactNode;
  onClick: () => void;
  isActive: boolean;
}

export function PaginationNumber({
  children,
  isActive,
  ...rest
}: PaginationNumberProps) {
  return (
    <PaginationButton isActive={isActive} {...rest}>
      <Text>{children}</Text>
    </PaginationButton>
  );
}

export function PaginationDots(props: any) {
  return (
    <PaginationButton {...props}>
      <Text>{DOTS}</Text>
    </PaginationButton>
  );
}

export function PaginationPrevious(props: any) {
  return (
    <PaginationButton isDirectionButton {...props}>
      <Icon className={styles.pagination_icon} icon="GtChevronSmallLeft" />
    </PaginationButton>
  );
}

export function PaginationNext(props: any) {
  return (
    <PaginationButton isDirectionButton {...props}>
      <Icon className={styles.pagination_icon} icon="GtChevronSmallRight" />
    </PaginationButton>
  );
}
